import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { customerContactsService } from '@/api';
import { CustomerContactsResource } from '@/resource/model';
import { Message } from 'element-ui';
import AddCustomerContacts from './add-customer-contacts/add-customer-contacts.vue';

@Component({
  name: 'CustomerContacts',
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    AddCustomerContacts
  }
})
export default class CustomerContacts extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<CustomerContactsResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 列配置
   */
  public columnOption: Array<OsTableColumn<CustomerContactsResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'contacts',
      label: 'contacts.realName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'telephone',
      label: 'contacts.telephone',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * 表格查询区域配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    }
  ];

  /**
   * 表格工具栏配置
   */
  public tableToolbarOption: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'company:contacts:save',
      handleClick: (): void => {
        this.openContactDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'company:contacts:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];

  /**
   * 列操作配置
   */
  public rowOperationOption = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'company:contacts:edit',
        handleClick: (item: CustomerContactsResource): void => {
          this.openContactDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'company:contacts:delete',
        handleClick: (item: CustomerContactsResource): void => {
          this.delete(item);
        }
      }
    ]
  };

  /**
   * 数据总记录数
   */
  public totalData = 0;
  /**
   * 选中记录
   */
  public selectedRows: Array<CustomerContactsResource> = [];
  /**
   * dialog显示状态
   */
  public syncedVisible = false;

  public editRow: CustomerContactsResource | null = null;

  /**
   * 查询表单数据对象
   */
  private queryForm: Partial<{
    keywords?: string;
    companyId: number;
  }> = {
    keywords: ''
  };

  /**
   * 分页对象
   */
  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadData();
  }

  /**
   * 表格多选回调函数
   * @param selectData 选中数据集合
   */
  public handleSelectionChange(selectData: Array<CustomerContactsResource>): void {
    this.selectedRows = selectData;
  }

  /**
   * 分页加载
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * 修改成功回调
   */
  public submitSuccess(data: CustomerContactsResource): void {
    const findItem = this.tableOption.data.find(row => row.id === data.id);

    Object.assign(findItem, data);
  }

  /**
   * 重新检索
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.contactTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 查询
   */
  public queryClick(): void {
    this.reloadData();
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerContactsResource>): void {
    this.tableToolbarOption.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import') {
        x.disabled = value.length === 0;
      }
    });
  }

  private openTableLoading(): void {
    this.setTableLoading(true);
  }

  private closeTableLoading(): void {
    this.setTableLoading(false);
  }

  private setTableLoading(value: boolean): void {
    this.tableOption.loading = value;
  }

  private clearSelectedRows(): void {
    this.selectedRows = [];
  }

  /**
   * 打开联系人新增/修改弹窗
   * @param data 表单数据对象
   */
  private openContactDialog(data: CustomerContactsResource | null = null): void {
    this.editRow = data;

    this.syncedVisible = true;
  }

  /**
   * 批量删除联系人
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          this.openTableLoading();
          const idList: Array<number> = this.selectedRows.map(row => row.id);
          await customerContactsService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        } finally {
          this.clearSelectedRows();
          this.closeTableLoading();
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 删除联系人
   * @param data 删除对象
   */
  private delete(data: CustomerContactsResource): void {
    showWarningConfirm(translation('tip.confirmDelete')).then(async () => {
      try {
        this.openTableLoading();
        await customerContactsService.delete(data.id);
        this.reloadData();
        Message.success(translation('operationRes.deleteSuccess'));
      } catch (error) {
        messageError(error);
      } finally {
        this.closeTableLoading();
      }
    });
  }

  /**
   * 表格数据查询
   */
  private loadData(): void {
    this.openTableLoading();
    this.queryForm.companyId = this.companyId;
    customerContactsService
      .getList(this.queryForm as CustomerContactsResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.closeTableLoading();
      });
  }
}
