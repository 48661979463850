import { customerContactsService } from '@/api';
import { CustomerContactsResource } from '@/resource/model';
import { Component, Prop } from 'vue-property-decorator';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { DialogMixin } from '@/mixins/dialog';
import { mixins } from 'vue-class-component';

@Component({
  components: {}
})
export default class AddCustomerContacts extends mixins(DialogMixin) {
  /**
   * 提交按钮loading属性
   */
  public submitLoading = false;

  /**
   * 传入联系人对象
   */
  @Prop({
    required: false
  })
  public contact: CustomerContactsResource | null = null;

  /**
   * 弹窗标题
   */
  public title = '';

  /**
   * 表单数据对象
   */
  public contactForm = {
    /**
     * 联系人姓名
     */
    contacts: '',
    /**
     * 联系人电话
     */
    telephone: '',
    /**
     * ID
     */
    id: undefined
  };

  /**
   * 表单验证规则
   */
  public formRules = {
    contacts: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('contacts.inputRealName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerContactsService
            .checkContactName(value, Number(this.$route.query.companyId as string), this.contactForm?.id)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.contactNameRepeat'));
                return;
              }

              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    telephone: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('contacts.inputTelephone')));
            return;
          }

          const regex = /^\d{8,20}$/;
          if(!regex.test(value)) {
            callback(new Error(translation('contacts.telephoneFormatError')))
            return;
          }

          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 操作类型,是否新增操作
   */
  private isCreate = true;

  /**
   * 打开弹窗事件
   */
  public dialogOpen(): void {
    if (!this.contact) {
      this.isCreate = true;
      this.title = 'contacts.addContact';
      return;
    }

    this.isCreate = false;
    this.title = 'contacts.editContact';

    Object.assign(this.contactForm, this.contact);
  }

  /**
   * dialog关闭事件
   */
  public handleClosed(): void {
    this.$emit('close-dialog');
    (this.$refs.contactForm as Form).resetFields();
  }

  /**
   * 提交表单事件
   */
  public handleSubmit(): void {
    (this.$refs.contactForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      this.openSubmitLoading();

      try {
        if (this.isCreate) {
          await customerContactsService.create({
            ...this.contactForm,
            companyId: this.$route.query.companyId
          } as any);

          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
        } else {
          await customerContactsService.edit({
            ...this.contactForm,
            companyId: this.$route.query.companyId
          } as any);

          Message.success(translation('operationRes.editSuccess'));
          this.$emit('edit-success', this.contactForm);
        }

        this.syncedVisible = false;
      } catch (error) {
        messageError(error);
      } finally {
        this.closeSubmitLoading();
      }
    });
  }

  private openSubmitLoading(): void {
    this.setSubmitLoading(true);
  }

  private closeSubmitLoading(): void {
    this.setSubmitLoading(false);
  }

  private setSubmitLoading(value: boolean): void {
    this.submitLoading = value;
  }
}
